$(document).ready(function () {

    $('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');

    $('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');

    $(".menu > ul > li").hover(function (e) {
        $(this).children("ul").stop(true, false).fadeToggle(150);
        e.preventDefault();
    });

    $("#menu").mmenu({
        "extensions": [
                "pagedim-black",
                "border-offset",
                "shadow-page"
             ],
        classNames: {
            fixedElements: {
                fixed: "menu-link"
            },
            vertical: "expand",
        }
    });

    $("#menu").mhead();

    var api = $('#menu').data('mmenu');
    api.bind("close:finish", function () {
        $('.ham').removeClass('active');
        $('.icon').removeClass('open');
    });

    $("nav .mm-listitem__text").click(
        function (evnt) {
            evnt.preventDefault();
            api.close();
        }
    )

    $("#menu").mhead();

    if ($(window).width() < 1200) {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st < lastScrollTop) {
                $('.menu-link').removeClass('mh-scrolledout');
            } else if (st < 80) {
                $('.menu-link').removeClass('mh-scrolledout');
            } else {
                $('.menu-link').addClass('mh-scrolledout');
            }

            lastScrollTop = st;
        });
    }

    $('.downscroll').click(function(){
        var offset = $('.information-block').offset();
        $('body, html').animate({scrollTop: offset.top}, 1200);
    });
});
